/* eslint-disable no-console */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { HomeContext } from 'src/components/Home/HomeProvider';

import classes from './Promotions.scss';

export type PromotionItem = {
    title: string;
    url: string;
    image: string;
};

type PromotionsProps = {
    items: PromotionItem[];
    variant: 'wide' | 'narrow' | 'banner';
};

export function Promotions(props: PromotionsProps) {
    return (
        <section className={classes.wrapper}>
            <div className={classes.container} data-ui-variant={props.variant}>
                <div className={classes.content} data-ui-variant={props.variant}>
                    {props.items.map((item) => (
                        <Link key={decodeHTMLEntities(item.title)} to={item.url} className={classes.link}>
                            <img className={classes.image} src={item.image} />
                            {props.variant !== 'banner' && <h3 className={classes.title}>{item.title}</h3>}
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}

export const PromotionsWrapper = React.memo(() => {
    const { promotions } = useContext(HomeContext);

    /**
     * Sort order is not support by the GraphQL query. Promotions are all technically the same Magento widget but we need to display them differently.
     * The first 8 promotions are displayed at the top of the home page.
     * The 9th promotion is displayed as a banner.
     * The rest of the promotions are displayed at the bottom of the home page.
     */
    const topPromotions = promotions.slice(0, 8);
    const bannerPromotion = promotions.slice(8, 9);
    const bottomPromotions = promotions.slice(9);

    return (
        <>
            {topPromotions.length > 0 && <Promotions items={topPromotions} variant="wide" />}
            {bannerPromotion.length > 0 && <Promotions items={bannerPromotion} variant="banner" />}
            {bottomPromotions.length > 0 && <Promotions items={bottomPromotions} variant="narrow" />}
        </>
    );
});

function decodeHTMLEntities(text) {
    const entities = [
        ['amp', '&'],
        ['#039', "'"],
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}
